import { isObject } from './utils/checkObject';

interface BindColorsProps {
  hits: any[];
  colors: Record<string, any>;
  wishlist?: any;
  store?: any;
}

const isWisWishList = (wishlistLineItems: any[], variantSku: string): boolean => {
  if (!wishlistLineItems || wishlistLineItems.length <= 0 || !variantSku) {
    return false;
  }

  return wishlistLineItems.some((item) => item.variant.sku === variantSku);
};

const getProductColors = (
  hit: any,
  colors: Record<string, any>,
  wishlistLineItems: any[],
  storeGln?: string,
): any[] => {
  const allColorsOfProduct = colors[hit.root_model_key] ?? [];

  return allColorsOfProduct
    .map((productColor) => {
      productColor.isWishList = isWisWishList(wishlistLineItems, productColor);
      productColor.isActive = productColor.sku === hit.sku;

      return productColor;
    })
    .filter((color): boolean => {
      if (!color) {
        return false;
      }

      if (storeGln) {
        const glnChannels = Array.isArray(color.gln_channels) ? color.gln_channels : [];

        return glnChannels.some((channel) => channel === storeGln);
      }

      return true;
    });
};

const bindColors = ({ hits, colors, wishlist, store }: BindColorsProps): any[] => {
  if (!colors || hits.length === 0) {
    return hits;
  }

  const storeGln = store?.gln;
  const wishlistLineItems = wishlist?.data?.lineItems || [];

  return hits
    .map((hit) => {
      if (!hit.root_model_key) {
        return null;
      }

      return { ...hit, ...{ colors: getProductColors(hit, colors, wishlistLineItems, storeGln) } };
    })
    .filter((hit) => !!hit);
};

const getChannelIds = (product) => {
  if (isObject(product?.projectSpecificData?.channelIds)) {
    return Object.values(product.projectSpecificData.channelIds);
  }

  if (Array.isArray(product?.projectSpecificData?.channelIds)) {
    return product.projectSpecificData.channelIds;
  }

  return [];
};

const getProductName = (selectedVariant: any) => {
  const attrShoeWidth =
    selectedVariant?.attributes?.att_prop_shoe_general_width?.toLowerCase() !== 'normal'
      ? selectedVariant.attributes?.att_prop_shoe_general_width
      : '';
  const attrGender = selectedVariant?.attributes?.att_prod_gender;
  const attrCategoryOnlineShop = selectedVariant?.attributes?.att_internal_category_online_shop;
  const attrProductName = selectedVariant?.attributes?.att_prod_product_name || selectedVariant?.name;

  let productName = attrProductName;

  if (attrShoeWidth) {
    productName += ` ${attrShoeWidth}`;
  }

  if (attrGender) {
    productName += ` ${attrGender}`;
  }

  if (attrCategoryOnlineShop) {
    productName += ` ${attrCategoryOnlineShop}`;
  }

  return productName;
};

export { bindColors, getChannelIds, getProductName };
